<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <div v-else>
    <v-container class="d-flex flex-column">
      <no-found-report v-if="!!errors" :errors="errors" />

      <template v-else>
        <h2>SERP Crawl Results</h2>
        <div class="header-row" style="margin-top: 10px">
          <div class="header-report">
            <div class="row-text">
              <span> Report Name: {{ report.report_name }} </span>
            </div>
            <div class="row-text">
              <span
                >Keyword: <a :href="googleLink(report.keyword)" target="_blank">{{ report.keyword }}</a>
              </span>
            </div>
            <div class="row-text">
              <div class="col-comparison">
                <span v-for="(url, index) in report.comp_urls" :key="index"
                  >Comparison URL: {{ index == 0 ? '' : index + 1 }} <a :href="url" target="_blank">{{ url }}</a>
                </span>
              </div>
            </div>
          </div>

          <div class="col-actions text-center">
            <dialog-export :report="report" />
            <dialog-share-report v-if="report && report.id" :report="report" report_type="serp_analysis" />
          </div>
        </div>
      </template>

      <all-components :loading="loading" :data="data" />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import AllComponents from './components/SerpAllComponents.vue'
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import DialogExport from './components/DialogExport.vue'
import DialogShareReport from '@/components/dialog/DialogShareReport.vue'
//components
import NoFoundReport from '@/components/report/NoFoundReport.vue'

export default {
  components: {
    AllComponents,
    ViewLoader,
    DialogExport,
    DialogShareReport,
    NoFoundReport,
  },
  data() {
    return {
      loading: false,
      errors: null,

      report: {},
      data: {},
      export_list: [
        { label: 'Page Metrics Excel', type: 'pagemetrics', format: 'excel' },
        { label: 'Page Metrics CSV', type: 'pagemetrics', format: 'csv' },

        { label: 'Domain link profile Excel', type: 'domainlinkprofile', format: 'excel' },
        { label: 'Domain link profile CSV', type: 'domainlinkprofile', format: 'csv' },

        { label: 'URL link profile Excel', type: 'urllinkprofile', format: 'excel' },
        { label: 'URL link profile CSV', type: 'urllinkprofile', format: 'csv' },

        { label: 'Backlinks Seen Excel', type: 'backlinksseen', format: 'excel' },
        { label: 'Backlinks Seen CSV', type: 'backlinksseen', format: 'csv' },

        { label: 'Domain Level Data Excel', type: 'domainleveldata', format: 'excel' },
        { label: 'Domain Level Data CSV', type: 'domainleveldata', format: 'csv' },

        { label: 'Page Level Data Excel', type: 'pageleveldata', format: 'excel' },
        { label: 'Page Level Data CSV', type: 'pageleveldata', format: 'csv' },

        { label: 'Link Gaps Analysis Excel', type: 'linkgapsanalysis', format: 'excel' },
        { label: 'Link Gaps Analysis CSV', type: 'linkgapsanalysis', format: 'csv' },

        { label: 'Anchors Aggregate Text Excel', type: 'anchorsaggregatetext', format: 'excel' },
        { label: 'Anchors Aggregate Text CSV', type: 'anchorsaggregatetext', format: 'csv' },

        { label: 'Speed and Core Web Vitals Comparison Excel', type: 'speedwebvitals', format: 'excel' },
        { label: 'Speed and Core Web Vitals Comparison CSV', type: 'speedwebvitals', format: 'csv' },
      ],
    }
  },
  mounted() {
    this.getReportData()
  },
  methods: {
    ...mapActions({
      getReport: 'serp_analysis/getReport',
      setSnackbar: 'snackbar/set',
    }),
    async getReportData() {
      this.loading = true
      await this.getReport(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
            this.report = resp.data.report
            this.errors = null
          }
        })
        .catch(error => {
          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
          this.setSnackbar({
            status: true,
            text: 'No report found.',
            color: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
    downloadReport($report_id, $format, $type) {
      const url = `${process.env.VUE_APP_SERVER_URL}/export/serpanalysis/${$type}/${$report_id}/${$format}`
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped>
.header-report {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.col-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 450px) {
  .header-row {
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
}
</style>
